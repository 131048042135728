<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="success" inline title="RMF" class="px-5 py-3">
          <v-form>
            <div id="dropzone" ref="dragZone" style="visibility:hidden; opacity:0">
              <div id="textnode">
                Drop files to add data.
              </div>
            </div>
            <v-container class="py-0" fluid>
              <v-row :dense="dense" class="text-no-wrap" align="center">
                <v-col cols="12">
                  <v-row :dense="dense" class="text-no-wrap" align="center">
                    <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                      {{ $t("Fișier") }}
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="record.file_name"
                        :label="$t('Fișier')"
                        :rules="inputValidate"
                        clearable
                        readonly
                        @click="$refs.file.click()"
                        @click:clear="
                          record.file_name = ''
                          record.file = null
                        "
                      >
                        <template v-slot:prepend>
                          <v-icon @click="$refs.file.click()">
                            mdi-paperclip
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-img v-if="record.image" height="100" contain :src="'data:image/jpeg;base64,' + record.image" /> -->
                  <!-- <v-img v-if="dictionary['urlImg']" height="100" contain :src="dictionary['urlImg']" />
                  <span v-else-if="record.file" :src="'data:image/jpeg;base64,' + record.file">
                    {{ record.file ? (dictionary["urlImg"] = "data:image/jpeg;base64," + record.file) : "" }}
                    {{ record.file ? (record.file = "") : "" }}
                  </span>

                  <v-btn v-else icon @click="$refs.file.click()">
                    <v-icon size="100">
                      mdi-camera
                    </v-icon>
                  </v-btn>
                   -->
                  <div v-show="false">
                    <input
                      ref="file"
                      type="file"
                      @change="
                        record.file = $event.target.files[0]
                        record.file_name = $event.target.files[0].name
                        record.name = $event.target.files[0].name
                        record.content_type = $event.target.files[0].type
                      "
                    />
                    <!-- accept="image/*" -->
                    <!-- createURL('urlImg', $event) -->
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-row :dense="dense" class="text-no-wrap" align="center">
                    <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                      {{ $t("Nume") }}
                    </v-col>
                    <v-col>
                      <v-text-field v-model="record.name" :hide-details="hided" :dense="dense" class="pt-0" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row :dense="dense" class="text-no-wrap" align="center">
                    <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                      {{ $t("Tip conținut") }}
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="record.content_type"
                        :hide-details="hided"
                        :dense="dense"
                        readonly
                        class="pt-0"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <!-- <v-col v-show="record._id" cols="12">
                  <v-row :dense="dense" class="text-no-wrap" align="center">
                    <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
                      {{ $t("Previzualizare") }}
                    </v-col>
                    <v-col>
                      <v-checkbox v-model="dictionary.preview_med" :hide-details="hided" :dense="dense" class="pt-0" />
                    </v-col>
                  </v-row>
                </v-col> -->
                <!-- <v-col v-if="dictionary.preview_med" cols="12">
                  <pdf
                    v-if="record.content_type == 'application/pdf'"
                    :src="baseURL + 'file_stream/?f=' + record._id"
                    :page="1"
                    :text="false"
                  ></pdf>
                </v-col> -->
                <v-col v-if="record._id" cols="12" class="d-flex justify-center">
                  <scream-pdf
                    v-if="record.content_type == 'application/pdf'"
                    :src="baseURL + 'file_stream/?f=' + record._id"
                    height="600px"
                  />
                  <v-img v-else-if="content_type == 'image'" :src="baseURL + 'file_stream/?f=' + record._id" />
                  <audio v-else-if="content_type == 'audio'" controls>
                    <source :src="baseURL + 'file_stream/?f=' + record._id" :type="record.content_type" />
                  </audio>
                  <video v-else-if="content_type == 'video'" class="v-responsive" controls>
                    <source :src="baseURL + 'file_stream/?f=' + record._id" :type="record.content_type" />
                  </video>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import pdfvuer from "pdfvuer"
//import pdf from "vue-pdf"
import ScreamPdf from "../../../components/containers/ScreamPdf"

import { EventBus } from "../../../EventBus"
import axios from "../../../plugins/axios"
//import axio from "axios"
//var loadingTask = pdf.createLoadingTask("/Hi3516DV300.pdf")
// var loadingTask = pdfvuer.createLoadingTask("http://:7780/file_stream/?f=601ba0b91dd2d1825301c83b")
export default {
  name: "DashboardDashboard",
  components: {
    ScreamPdf
  },
  data() {
    return {
      // src: loadingTask,
      numPages: undefined,
      dense: true,
      hided: false,
      fileName: "",
      file: {},
      urlImg: null,
      searchId: "",
      dictionary: {},
      linkArray: null,
      linkField: null,
      linkSubfield: null,
      linkIndex: null,
      selectId: null,
      render: 1,
      record: {
        company: "",
        id: "",
        ctg: "",

        unknown_fields: [{}]
      },
      recordView: {
        company: "",
        id: "",
        ctg: "",

        unknown_fields: [{}]
      },
      schema: {
        dictionary: ["all.CategoriiServicii", "all.TipCerere"]
      },
      rows: []
    }
  },

  computed: {
    content_type() {
      if (this.record.content_type.substr(0, 5) == "image") {
        return "image"
      } else if (this.record.content_type.substr(0, 5) == "audio") {
        return "audio"
      } else if (this.record.content_type.substr(0, 5) == "video") {
        return "video"
      }
      return this.record.content_type
    },
    baseURL() {
      return axios.defaults.baseURL
    },
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  created() {
    if (this.schema.dictionary) {
      this.schema.dictionary.map(itm => this.getDictionaryValues(itm))
    }
    const country = []
    this.rows.map(itm => {
      country.push({ name: itm.value, value: itm.value })
    })
    EventBus.$on("dictionary-reload", dict => {
      this.$log("dictionary-reload ", dict)
      if (this.selectId == dict.id) {
        this.getDictionaryValues("all." + dict.dictionary)
        if (dict.select) {
          this.setLinkValue(dict.select)
        }
      }
    })
    // EventBus.$on("table-form-select", value => {
    //   this.$log("selected ", value)
    // })
    //this.$log(JSON.stringify(country))
    // axios.post("anaf_check_tva", [{ cui: 18158683, data: "2020-04-30" }]).then(response => {
    //   this.$log("response ", response)
    // })
  },
  mounted() {
    // this.src.then(pdf => {
    //   this.numPages = pdf.numPages
    // })
  },
  methods: {
    validateValue(fld, sub) {
      const rule = []
      //rule.push(v => !!v || this.$t("required"))
      return rule
    },
    getInv() {
      axios.get('ebib_loan_active?aggregate={"$where":{},"$sort":{}}&max_results=10').then(response => {
        this.$log("resinvtm ", response)
      })
    },
    createURL(img, fil) {
      this.$set(this.dictionary, img, URL.createObjectURL(fil.target.files[0]))
    },
    fileChanged(e) {
      this.file = e.target.files[0]
      this.urlImg = URL.createObjectURL(this.file)
      this.fileName = this.file.name
    },
    clearFile() {
      this.fileName = ""
      this.file = null
    },
    createAndEdit(e, arr, field, ind) {
      //searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.selectId = Date.now()
      this.$log("createAndEdit ", e)
      this.$refs.createEdit.showData(e, this.selectId)
    },
    checkTVA(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
          }
        })
      }
    },

    getdictionary(dic) {
      return this.$store.getters.dictionary(dic)
    },
    getDictionaryValues(dic) {
      //this.$log("getDictionaryValues(itm) ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        this.$set(this.dictionary, dictionary[1], this.getdictionary("all_" + dictionary[1]))
        // this.dictionary[dictionary[1]] = response.data._items[0].content
        //})
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      }
    },
    addSubItem(e, ind) {
      if (ind == null) {
        this.resolvePath(e, this.record).push(JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0])))
      } else {
        this.resolvePath(e, this.record).splice(
          ind,
          0,
          JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0]))
        )
      }
    },
    delSubItem(e, ind) {
      this.resolvePath(e, this.record).splice(ind, 1)
      if (this.resolvePath(e, this.record).length == 0) {
        this.addSubItem(e)
      }
    },
    searchRelationMarc(field, subfield, ind, prefix, ctg) {
      this.linkField = field
      this.linkSubfield = subfield
      this.linkIndex = ind
      //this.$log("srcrel ", this.schemaContent[fields.field + subfield])
      this.searchId = Date.now()
      let where = ""
      let vTable = ""
      if (ctg) {
        where = '"ctg":' + '"' + ctg + '"'
        const ctgview = this.$store.getters.appViewTableCollectionCategory("marc_" + prefix, ctg)
        if (ctgview.length > 0) {
          vTable = ctgview[0]._id
        }
      }
      this.$refs.searchLink.open("ebib_marc_" + prefix, null, this.searchId, {
        where: where,
        viewTable: vTable,
        ctg: ctg
      })
      // EventBus.$emit("search-link", {
      //   resource: "ebib_marc_" + prefix,
      //   field: null,
      //   id: this.searchId,
      //   config: { where: where, viewTable: vTable, ctg: ctg }
      // })
      //this.$refs.searchLink.open(res, fld, this.searchId)
    },
    searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.searchId = Date.now()
      this.$refs.searchLink.open(res, fld, this.searchId)
    },
    setLink(field, subfield, ind, prefix, ctg, e) {
      this.$log("eeee ", e)
    },
    setLinkValue(e) {
      if (this.linkIndex != null) {
        if (this.linkField != null) {
          //this.linkArray[this.linkIndex][this.linkField] = e
          this.$set(this.linkArray[this.linkIndex], this.linkField, e)
        } else {
          if (typeof e === "object") {
            e.iiid = this.idGlobal++
          }
          if (this.linkIndex == -1) {
            this.linkArray.push(e)
          } else {
            //this.linkArray[this.linkIndex] = e
            this.$set(this.linkArray, this.linkIndex, e)
          }
        }
      } else {
        //this.linkArray[this.linkField] = e
        this.$set(this.linkArray, this.linkField, e)
        this.render = Date.now()
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
